<template>
    <footer class="pt-xs-40 pt-md-50 pb-xs-60 pb-md-180">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-3 mb-xs-40 mb-sm-0">
            <img class="logo" src="../../assets/netvise-logo.svg" width="125" alt="">
            <p>Copyright &copy; Netvise {{ new Date().getFullYear() }}</p>
          </div>

          <div class="col-xs-6 col-sm-3 col-md-2 col-md-offset-3">
            <div class="footer-list">
              <div class="footer-list__title">Sitemap</div>
              <ul>
                <li><a href="#link">{{ $t('navigation.home') }}</a></li>
                <li><a href="#link">{{ $t('navigation.services') }}</a></li>
                <li><a href="#link">{{ $t('navigation.about') }}</a></li>
                <li><a href="#link">{{ $t('navigation.support') }}</a></li>
                <li><a href="#link">{{ $t('navigation.contact') }}</a></li>
              </ul>
            </div>
          </div>

          <div class="col-xs-6 col-sm-3 col-md-2">
            <div class="footer-list">
              <div class="footer-list__title">Tools</div>
              <ul>
                <li><a href="https://speed.netvise.be/" target="_blank">Speedtest</a></li>
              </ul>
            </div>
          </div>

          <div class="col-xs-12 col-sm-3 col-md-2 mt-xs-20 mt-sm-0">
            <div class="footer-list --social">
              <div class="footer-list__title">Social</div>
              <ul>
                <li><a class="icon-before icon-big icon-social-linkedin" href="https://www.linkedin.com/company/netvise" target="_blank"></a></li>
                <li><a class="icon-before icon-big icon-instagram" href="https://www.instagram.com/netvisebv" target="_blank"></a></li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </footer>
</template>

<script>
import { Trans } from '@/plugins/Translation'
export default {
  name: "Footer",
}
</script>
