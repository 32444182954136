import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import api from '@/services/api.service';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGtag from "vue-gtag";
import { i18n } from '@/plugins/i18n';
import { Trans } from '@/plugins/Translation';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueAnimateOnScroll from 'vue-animate-onscroll';
let VueScrollTo = require('vue-scrollto');

Vue.use(VueAxios, axios);

Vue.use(require('vue-cookies'));

Vue.use(VueScrollTo, {
  container: "body",
  duration: 800,
  easing: [0.790, 0.005, 0.215, 1.000],
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.use(VueAwesomeSwiper);
Vue.use(VueAnimateOnScroll);

Vue.use(VueGtag, {
  config: { id: "G-0YR859VTVJ" }
});

import 'swiper/css/swiper.css';

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);
Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, '$api', {
  get () {
    return api
  }
});

require('./styles/main.scss');

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
