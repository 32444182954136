import axios from 'axios'
import { Trans } from '@/plugins/Translation'

let http = null;

class APIService {
  pages = null;
  posts = null;

  
  constructor ({url}) {
    http = axios.create({
        baseURL: url,
        headers: { 'Content-Type': 'application/json' }
    });
  }


  /* POSTS */
  getPost(id) {
    return this.getPosts().then(posts => posts.find(post => post.id === id));
  }
  
  getPosts() {
    if(!this.posts){
      const params = {params: {lang: Trans.currentLanguage}};
      this.posts = http.get('posts', params).then(response => response.data);
    }
    return this.posts;
  }


  /* PAGES */
  getPage(slug) {
    return this.getPages().then(pages => pages.find(page => page.slug === slug));
  }

  getPages() {
    if(!this.pages){
      const params = {params: {lang: Trans.currentLanguage}};
      this.pages = http.get('pages', params).then(response => response.data);
    }
    return this.pages;
  }
}

export default new APIService({
  url: process.env.VUE_APP_API_URL
});
