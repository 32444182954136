<template>
  <div id="app">
    <router-view />
    <Footer />

    <!-- <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
          <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
        </filter>
      </defs>
    </svg> -->
  </div>
</template>

<script>
import Footer from '@/components/shared/Footer';
export default {
  name: 'app',
  components: {
    Footer
  }
}
</script>
